import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";

import "@stylesPages/Terms.scss";

import SEO from "@components/SEO";
import Layout from "@components/Layout";

export default function Terms() {
  const { t } = useTranslation("privacy");

  return (
    <Layout>
      <SEO title="Terms" />
      <section className="terms">
        <div className="terms__container">
          <h1 className="terms__title">{t("title")}</h1>
          <p className="terms__text">{t("paragraphOne")}</p>
          <h2 className="terms__subtitle">{t("titleTwo")}</h2>
          <p className="terms__text">{t("paragraphTwo")}</p>
          <h2 className="terms__subtitle">{t("titleThree")}</h2>
          <p className="terms__text">{t("paragraphThree")}</p>
        </div>
      </section>
    </Layout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
